exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-gitex-js": () => import("./../../../src/pages/blog/gitex.js" /* webpackChunkName: "component---src-pages-blog-gitex-js" */),
  "component---src-pages-blog-kdsblog-js": () => import("./../../../src/pages/blog/kdsblog.js" /* webpackChunkName: "component---src-pages-blog-kdsblog-js" */),
  "component---src-pages-blog-menu-js": () => import("./../../../src/pages/blog/menu.js" /* webpackChunkName: "component---src-pages-blog-menu-js" */),
  "component---src-pages-blog-pos-js": () => import("./../../../src/pages/blog/pos.js" /* webpackChunkName: "component---src-pages-blog-pos-js" */),
  "component---src-pages-blog-posblog-js": () => import("./../../../src/pages/blog/posblog.js" /* webpackChunkName: "component---src-pages-blog-posblog-js" */),
  "component---src-pages-blog-posimpact-js": () => import("./../../../src/pages/blog/posimpact.js" /* webpackChunkName: "component---src-pages-blog-posimpact-js" */),
  "component---src-pages-blog-qrblog-js": () => import("./../../../src/pages/blog/qrblog.js" /* webpackChunkName: "component---src-pages-blog-qrblog-js" */),
  "component---src-pages-blog-toppos-js": () => import("./../../../src/pages/blog/toppos.js" /* webpackChunkName: "component---src-pages-blog-toppos-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-hardware-js": () => import("./../../../src/pages/hardware.js" /* webpackChunkName: "component---src-pages-hardware-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-pos-js": () => import("./../../../src/pages/pos.js" /* webpackChunkName: "component---src-pages-pos-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-product-backoffice-js": () => import("./../../../src/pages/product/backoffice.js" /* webpackChunkName: "component---src-pages-product-backoffice-js" */),
  "component---src-pages-product-kds-js": () => import("./../../../src/pages/product/kds.js" /* webpackChunkName: "component---src-pages-product-kds-js" */),
  "component---src-pages-product-mobile-js": () => import("./../../../src/pages/product/mobile.js" /* webpackChunkName: "component---src-pages-product-mobile-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-term-js": () => import("./../../../src/pages/term.js" /* webpackChunkName: "component---src-pages-term-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

